import type { ReactNode } from 'react';
import { useRef } from 'react';
import { UNSTABLE_PortalProvider as PortalProvider } from '@pledge-earth/product-language';

import { useAppSelector } from '../../store/hooks';
import { FeatureFlag } from '../FeatureFlag/FeatureFlag';

export function Theme({ children }: { children: ReactNode }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useAppSelector((state) => state.settings.theme);

  return (
    <FeatureFlag name="theme-preference" fallback={children}>
      <div className={theme === 'dark' ? 'ppl-theme-dark' : 'ppl-theme-light'} ref={containerRef}>
        <PortalProvider getContainer={() => containerRef.current}>{children}</PortalProvider>
      </div>
    </FeatureFlag>
  );
}
